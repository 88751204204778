@tailwind base;
@tailwind components;
@tailwind utilities;

    
  @keyframes cursor {
      0%, 100% { 
      border-color: #FFFFFF; 
    }
  }
  
  @keyframes text {
      0% { 
      width: 0; 
    }
      100% { 
      width: 18ch; 
    }
  }


html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

  
.resumeTab {
  border: 1px solid black;
  border-radius: 3px;
  display: block;

  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.resumeTab:hover {
  background-position: left bottom;
}

.resumeText {
  text-align: center;
  font-size: 16px;
  line-height: 30px; 
  color: black;
  transition: all .6s ease-out;
  display: block;
}

.resumeText:hover {
  color: white;  
}

.rightShadow {
  box-shadow: 5px 0px 0px gray
}

.scroll-up {
  transition: transform 0.3s ease-in-out;
  transform: translateY(0%);
}

.scroll-down {
  transition: transform 4s ease-in-out;
  transform: translateY(-100%);
}